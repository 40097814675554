// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { Link } from "react-router-dom";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {  items: this.props.items, 
                    activeItemId: this.props.mainMenuState
                  };
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.canInteract= this.props.canInteract;
  }

  componentWillReceiveProps(newProps) {
    if(newProps.canInteract !== undefined)
    {
      this.canInteract = newProps.canInteract;
    }

    if(newProps.mainMenuState !== undefined)
    {
      this.setState({
        activeItemId: newProps.mainMenuState
      });
    }
  }

  onMenuItemClick(event, item) {
   
    if(!this.canInteract)
    {
      return;
    }
      this.setState({ activeItemId: item.id });
      this.props.onMenuItemClick(event, item);
  }

  getPathRender(value)
  {
    if(value > 0)
    {
      var arr = [];
      for(var i=0; i < value; i++)
      {
        arr.push(<span key={i} className={`path${i+1}`}></span>)
      }
      return arr;
    }
  }

  render() {
    const { items, activeItemId } = this.state;

    return (
      <ul className="bottom-icons-nav">
        <li className="show-on-desktop desktop__logo">
          <Link to={{ pathname: "/home" }}>
            <img src="assets/images/logos/logo-icon.png" alt="" />
          </Link>
        </li>
        {items.map((item) => (
          <li
            key={item.id}
            onClick={(event) => this.onMenuItemClick(event, item)}
          >
            <a className={item.id == activeItemId ? "active" : ""} >
              <i className={item.class}>
                {item.path > 0 ? this.getPathRender(item.path) : (null) }
              </i>
            </a>
          </li>
        ))}
      </ul>
    );
  }
}

export default Menu;
