// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import "./App.css";
import { Route, HashRouter, Redirect } from "react-router-dom";
import NotLive from "./components/NotLive";
import Login from "./components/Login";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Loader from "./components/Loader";
import { FirebaseContext } from "./firebase";
import { createHashHistory } from "history";
import { isMobileOnly, isTablet } from 'react-device-detect';
export const history = createHashHistory();

class App extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state =
    {
      update: false,
      isLive: false,
      checkingStatus: true,
      statusMessage: null,
    }
  }
  componentDidMount() {
    if (isMobileOnly) {
      window.location.href = "/issue/index.html";
    }
    this.checkEventStatus();
    this.listener = this.context.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        console.log(authUser.email);
        this.context.currentUser = authUser;
        this.UserOnlineTest(authUser);
        // history.push("/home");
      } else {
        console.log("user not logged in--");
        history.push("/login");
      }
    });
  }

  //#region  utility functions
  UserOnlineTest = (user) => {
    var self = this;
    var users = this.context.database.ref().child('loggedInUser').child(user.uid);
    var query = users.orderByChild('state');
    query.once("value", snapshot => {
      if (snapshot.exists()) {
        // console.log("old user");
        const userData = snapshot.val();
        console.log("status:", userData.state);

        if (userData.state === "online") {
          console.log("Already Signed In");
          self.context.auth.signOut();
          self.context.alreadyLoggedIn = true;
          history.push("/login");

          this.setState({
            update: true
          });
          // history.push({
          //   pathname: '/login',
          //   state: { online: true}
          // })
        } else {
          // console.log("user camer back");
          self.validUser(user);
        }
      } else {
        // console.log("first time user");
        self.validUser(user);
      }
    });
  }

  validUser(user) {
    this.updateUserStatus(user);
    this.context.alreadyLoggedIn = false;
    this.context.checkUserAdmin();
    this.context.checkForAnalytics();
    history.push("/home");
  }

  updateUserStatus(user) {
    const uid = user.uid;
    const userStatusDatabaseRef = this.context.database.ref('/loggedInUser/' + uid);
    const isOfflineForDatabase = {
      state: 'offline',
      last_changed: this.context.app.database.ServerValue.TIMESTAMP,
    };
    const isOnlineForDatabase = {
      state: 'online',
      last_changed: this.context.app.database.ServerValue.TIMESTAMP,
    };

    this.context.database.ref('.info/connected').on('value', function (snapshot) {
      if (snapshot.val() == false) {
        return;
      };
      userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
        userStatusDatabaseRef.set(isOnlineForDatabase);
      });
    });
  }


  //#endregion

  checkEventStatus = () => {
    var self = this;
    var firstTime = true;
    this.eventStatusLisenter = this.context.firestore.collection("backStage").doc("eventStatus").onSnapshot(function (doc) {
      const data = doc.data();
      if (data) {
        // self.context.todayDate = data.date;
        // self.context.extraLoginNumbers = data.extraLoginNumbers;
        if(!firstTime && data.isLive)
        {
          window.location.href="/index.html";
          return;
        }
        var _message =
        {
          heading: data.heading,
          message: data.message
        }
        self.setState({
          isLive: data.isLive,
          checkingStatus: false,
          statusMessage: _message
        });
        firstTime =false;
      }
    });
  }

  bypass = () => {
    this.setState({
      isLive: true,
      checkingStatus: false
    });
  }



  componentWillUnmount() {
    this.listener();
  }

  render() {
    return (
      <>
        {this.state.isLive ? (
          <>
            <HashRouter>
              <Route exact path="/">
                <Redirect to="/loader" />
              </Route>
              {/* <Route path="/login" component={Login} /> */}
              <Route path="/login" render={(props) => <Login {...props} alreaylogged={this.context.alreadyLoggedIn} />} />

              <Route path="/contact" component={Contact} />
              <Route path="/home" component={Home} />
              <Route path="/loader" component={Loader} />
            </HashRouter>
          </>)
          : (
            <>
              <NotLive checking={this.state.checkingStatus} messageObj={this.state.statusMessage} bypass={this.bypass}></NotLive>
            </>
          )}
      </>
    );
  }

}

export default App;
