import React, { Component } from "react";
import { FirebaseContext } from "../firebase/index";

const buttonStyle=
{
  width: "50px",
  height: "50px",
  background: "#0000003b",
  position: "absolute",
  top: "0",
  left: "0",
  zIndex: "500",
  border: "0",
};

class NotLive extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.counter = 0;
    this.incCounter = this.incCounter.bind(this);
  }

  incCounter = () => 
  {
    this.counter += 1;
    if(this.counter > 5)
    {
      this.props.bypass();
    }
  }

  render() {
    return (
      <>
       <button style={buttonStyle}  onClick={this.incCounter}></button>
        <section className="loginBox">
          <div className="loginBox__logo default-padding pd-t35 pd-b20">
            {/* pd-t35 pd-b20"> */}
            { <img className="h-80" src="/assets/images/logos/logo-icon.png" alt="" /> }
          </div>
          <div className="scrollable-part">
              <div className="default-padding pd-t35 pd-b30">
                <>
                    {
                        this.props.checking ? 
                        ( <h2 className="bold-text light-color font-22 mg-b25">
                            Checking Event Status..
                        </h2>) 
                        : ( 
                            <>
                        <h2 className="bold-text light-color font-22 mg-b25">
                        {this.props.messageObj ? this.props.messageObj.heading : "Event Not Live" } 
                        </h2>
                        <div className="form-group light-color">
                        <p>
                        {this.props.messageObj ? this.props.messageObj.message : "Event will be live on 5 Aug, 2020 at 5:00PM" } 
                        </p>
                        </div>
                        </>
                        )
                    }
                </>
               
              </div>
          </div>
        </section>
        <section className="contentCntr">
          <article
            className="videoBox img-bg menu-active"
            style={{
              "backgroundImage": "url('assets/images/canvas-img_2.jpg')",
            }}
          >
            <button className="fast-forward-btn"></button>
            <button className="fast-backward-btn"></button>
          </article>
        </section>
      </>
    );
  }
}

export default NotLive;
